<template>
  <div class="settings-container">
    <setting-nav />
    <div class="content-container">
      <section id="sectionID_1" class="section">
        <div class="section-header-body" style="margin-top: 0;">
          <h3 class="section-header">İşini <span>Tanıyalım</span></h3>
          <h2 class="section-sub-header">
            Bu alana gireceğin bilgiler Bionluk deneyimini sana göre uyarlamamıza yardımcı olur. Hiçbir bölümde gösterilmez.
          </h2>
        </div>
        <div class="section-body">
          <!--loader-->
          <div class="partial-loader" v-if="loader">
            <loader />
          </div>
          <input id="selectedFile" accept="image/*" type="file" style="display: none;" @change="sendImageToCropModal"/>
          <!--editable area-->
          <div>
            <div style="display: flex">
              <div class="user-avatar">
                <img :src="tempBusinessInfo.logoUrl" alt="user avatar" class="user-avatar-img">
                <div class="add-image" @click="setImage()">
                  <img src="https://gcdn.bionluk.com/site/c/ic-add-or-plus.svg" onload="SVGInject(this)" class="avatar-add-img">
                </div>
              </div>
              <div style="margin-left: 24px;">
                <p style="width: 128px; color: #4B4F52">İşletme Logosu</p>
                <p class="mt-1" style="color: #4B4F52; opacity: 0.5">(İsteğe Bağlı)</p>
              </div>
            </div>
            <div class="user-info" style="margin-top: 48px">
              <custom-input
                label="İşletme Adı"
                place-holder="Örn: Bionluk"
                inputType="textArea"
                v-model="tempBusinessInfo.name"
                :valid="tempBusinessInfo.name !== businessInfo.name ? true : null"
                maxlength="60"
                style="height: 48px; width: 393px;"
              />
              <custom-input
                label="İşletme Hakkında"
                inputType="textArea"
                v-model="tempBusinessInfo.description"
                :valid="tempBusinessInfo.description !== businessInfo.description ? true : null"
                maxlength="600"
                style="width:658px; height: 200px; margin-top: 48px"
              />
              <custom-select
                id="organization-type-select"
                label="İşletme Tipi"
                :options="organizationTypes"
                :customOption="{name: 'name', value: 'value'}"
                v-model="tempBusinessInfo.organizationType"
                :valid="tempBusinessInfo.organizationType !== businessInfo.organizationType ? true : null"
                style="width: 393px; margin-top: 48px;"
              />
              <custom-select
                id="business-stage-select"
                label="İşletmen hangi aşamada?"
                :options="businessStages"
                :customOption="{name: 'name', value: 'value'}"
                v-model="tempBusinessInfo.stage"
                :valid="tempBusinessInfo.stage !== businessInfo.stage ? true : null"
                style="width: 393px; margin-top: 36px;"
              />
              <custom-select
                id="employee-number-select"
                label="İşletmende kaç kişi çalışıyor?"
                :options="employeeNumbers"
                :customOption="{name: 'name', value: 'id'}"
                v-model="tempBusinessInfo.numberOfEmployeesId"
                :valid="tempBusinessInfo.numberOfEmployeesId !== businessInfo.numberOfEmployeesId ? true : null"
                style="width: 393px; margin-top: 36px;"
              />
              <div style="display: flex; align-items: center; margin-top: 36px;">
                <custom-select
                  id="industries"
                  label="Hangi sektörlerde hizmet veriyorsun?"
                  placeHolder="Sektör seçmek için bu alanı doldurmaya başla..."
                  inputType="search"
                  helpText="En fazla 2 seçim yapabilirsin."
                  :disabled="disableIndustryInput"
                  :options="industrySearchResults"
                  :exact-match-exists="exactIndustryExists"
                  :customOption="{ name: 'name', value: 'id' }"
                  v-model="industrySearchTerm"
                  @change="selectBoxOnChange"
                  @focus="openSkillTooltip = false"
                  style="width: 393px;"
                >
                  <li class="not-founded-select-box-item suggestion-text">
                    Böyle bir sonuç bulamadık. 😔
                  </li>
                </custom-select>
              </div>
              <div class="editable-skills-body">
                <div v-for="industry in tempBusinessInfo.industries" :key="industry.id">
                  <div class="editable-skill-item" @click="deleteIndustry(industry)">
                    {{industry.name}}
                    <img src="https://gcdn.bionluk.com/site/icon/ic_close_black.svg" onload="SVGInject(this)" class="delete-skill-icon"/>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="separator" style="margin-top: 30px;" />
          <div class="save-cancel-body">
            <custom-button buttonType="green" style="width: 170px; margin-left: 30px;" :disabled="isSaveButtonDisabled" @click="updateBasicInfo">Kaydet</custom-button>
          </div>
        </div>
      </section>
    </div>
    <div class="grey-background"></div>
  </div>
</template>

<script>
  import _difference from 'lodash/difference'
  import _isNil from 'lodash/isNil'
  import { cloneDeep } from '@/utils/helper'
  import Api from '@/utils/request'
  import SettingNav from "../navComponent"
  import CustomInput from "../../../components/CustomInput"
  import CustomButton from "../../../components/CustomButton"
  import CustomSelect from "../../../components/CustomSelect"
  import helpBox from "../../../components/HelpBox"
  import Fuse from "fuse.js"

  export default {
    name: 'src-pages-body-settings-business-v1',
    components: {
      SettingNav,
      CustomInput,
      CustomButton,
      CustomSelect,
      helpBox
    },
    data() {
      return {
        loader: false,
        businessInfo: {
          logoUrl: null,
          name: null,
          description: null,
          organizationType: null,
          stage: null,
          numberOfEmployeesId: null,
          industries: []
        },
        tempBusinessInfo: {
          logoUrl: 'https://gcdn.bionluk.com/uploads/message/d6c47a48-0a52-4b64-bb56-26fdc045b226.png',
          name: null,
          description: null,
          organizationType: null,
          stage: null,
          numberOfEmployeesId: null,
          industries: []
        },
        industries: [],
        businessStages: [],
        industrySearchTerm: '',
        industrySearchResults: [],
        exactIndustryExists: 0,
        disableIndustryInput: false,
        openSkillTooltip: true,
        organizationTypes: [],
        employeeNumbers: [],
        croppedFile: null,
        fuse: null
      }
    },
    methods: {
      init() {
        this.loader = true
        Api.get('/users/business-info')
          .then(({ data }) => {
            if (data.userBusiness) {
              this.setInitialValues(data.userBusiness)
            }
            this.industries = data.industries
            this.employeeNumbers = data.numberOfEmployees
            this.organizationTypes = data.organizationTypes
            this.businessStages = data.businessStages

            const options = { keys: ['name'], includeMatches: true, ignoreLocation: true, threshold: 0.4, findAllMatches: true }
            const index = Fuse.createIndex(options.keys, this.industries)
            this.fuse = new Fuse(this.industries, options, index)
          })
          .finally(() => {
            this.loader = false
          })
      },
      setInitialValues(userBusiness) {
        this.businessInfo.name = userBusiness.name
        this.businessInfo.description = userBusiness.description
        this.businessInfo.organizationType = userBusiness.organizationType
        this.businessInfo.stage = userBusiness.stage
        this.businessInfo.numberOfEmployeesId = userBusiness.numberOfEmployeesId
        this.businessInfo.logoUrl = userBusiness.logoUrl
        this.businessInfo.website = userBusiness.website
        this.businessInfo.industries = userBusiness.industries || []

        this.tempBusinessInfo = cloneDeep(this.businessInfo)
      },
      async updateBasicInfo() {
        this.loader = true
        try {
          let uploadId
          if (!_isNil(this.croppedFile)) {
            const timeStamp = +new Date()
            const result = await this.uploadFileToGoogle(this.croppedFile.file, this.Constants.UPLOAD_TYPES.BLOG, null, timeStamp)
            this.tempBusinessInfo.logoUrl = this.Constants.CDN_UPLOADS_G + result.uploadName
            uploadId = result.externalID
          }
          const payload = {
            name: this.tempBusinessInfo.name,
            description: this.tempBusinessInfo.description,
            organizationType: this.tempBusinessInfo.organizationType,
            stage: this.tempBusinessInfo.stage,
            numberOfEmployeesId: this.tempBusinessInfo.numberOfEmployeesId,
            industryIds: this.tempBusinessInfo.industries.map(industry => industry.id),
            uploadId
          }
          await Api.post('/users/business-info', payload)
          this.setInitialValues(this.tempBusinessInfo)
          this.$toasted.global.infoToast({description: "İşlem tamam!"})
        } catch(e) {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE})
        } finally {
          this.loader = false
        }
      },
      selectBoxOnChange(e) {
        this.tempBusinessInfo.industries.push({ name: e.name, id: e.id })
        this.industrySearchTerm = ''
      },
      deleteIndustry(receivedIndustry) {
        const foundIndex = this.tempBusinessInfo.industries.findIndex(industry => industry.id === receivedIndustry.id )
        this.tempBusinessInfo.industries.splice(foundIndex, 1)
      },
      setImage() {
        document.getElementById('selectedFile').click();
      },
      sendImageToCropModal(e) {
        this.$store.commit(this.types.ACTIVE_MODAL, {
          modalType: this.Modals.CROPPER,
          info: {file: e.target.files[0], canvasPixel: {width: 400, height: 400}, divider: 1.1, viewMode: 1}
        });
        document.getElementById("selectedFile").value = "";
      }
    },
    computed: {
      isSaveButtonDisabled() {
        if (this.loader) return true
        const tempIndustryIds = this.tempBusinessInfo.industries.map(industry => industry.id)
        const industryIds = this.businessInfo.industries.map(industry => industry.id)
        return (
          this.tempBusinessInfo.name === this.businessInfo.name &&
          this.tempBusinessInfo.description === this.businessInfo.description &&
          this.tempBusinessInfo.logoUrl === this.businessInfo.logoUrl &&
          this.tempBusinessInfo.numberOfEmployeesId === this.businessInfo.numberOfEmployeesId &&
          this.tempBusinessInfo.stage === this.businessInfo.stage &&
          this.tempBusinessInfo.organizationType === this.businessInfo.organizationType &&
          (!_difference(tempIndustryIds, industryIds).length && !_difference(industryIds, tempIndustryIds).length)
        )
      }
    },
    watch: {
      'tempBusinessInfo.industries'(newVal) {
        this.disableIndustryInput = newVal && newVal.length >= 2
      },
      industrySearchTerm(newValue) {
        if (newValue.length < 20) {
          this.industrySearchResults = this.fuse.search(newValue.trim()).map(result => result.item).filter(result => !this.tempBusinessInfo.industries.find(industry => industry.id === result.id))
          this.exactIndustryExists = !!this.industries.find(skill => skill.name.toLocaleLowerCase('tr') === newValue.trim().toLocaleLowerCase('tr'))
        } else {
          this.industrySearchResults = []
        }
      }
    },
    created() {
      this.EventBus.$on('refreshBusinessInfo', this.init)
      this.init()
      this.EventBus.$on('cropperImageFile', payload => {
        this.croppedFile = payload
        let reader = new FileReader()

        reader.onload = (e) => {
          this.Helper.trackEvents.customEvent("uploaded_business_logo", { version: 'v1', whereFrom: this.$store.state.componentMap.page, clickedFrom: 'settings' })
          this.tempBusinessInfo.logoUrl = e.target.result
        };

        reader.readAsDataURL(payload.file)
      })
    },
    beforeDestroy() {
      this.EventBus.$off("cropperImageFile")
      this.EventBus.$off('refreshBusinessInfo')
    }
  }

</script>

<style scoped lang="scss">


.settings-container {
  display: flex;
  min-height: calc(100vh - 111px);
  width: 1140px !important;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
}

.content-container {
  box-sizing: border-box;
  padding-top: 40px;
  padding-left: 30px;
  padding-bottom: 340px;
  z-index: 1;
  background-color: #f4f5f7;
  width: 100%;
  min-height: calc(100vh - 111px);
}

.grey-background {
  z-index: -1;
  position: fixed;
  top: 0;
  right: 0;
  width: 50vw;
  min-height: calc(100vh);
  background-color: #f4f5f7;
}

.section {
  display: flex;
  flex-direction: column;
  position: relative;
}

.section-header-body {

  margin-bottom: 20px;
  margin-top: 60px;

  .edit-area {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .edit-text {
      font-size: 14px;
      font-weight: 400;
      color: #8b95a1;
      margin-left: 5px;
    }
  }
}

.section-header {
  font-size: 24px;
  font-weight: 400;
  color: #4b4f52;

  span {
    font-weight: 600;
  }
}

.section-sub-header {
  font-size: 17px;
  color: rgb(118, 129, 145);
  font-weight: normal;
  margin-top: 10px;
  line-height: 1.55;
}

.section-body {
  position: relative;
  box-sizing: border-box;
  border: 1px solid #eaedf2;
  background-color: #fff;
  min-width: 100%;
  padding: 58px 44px;
}

.separator {
  border-bottom: 1px solid #eaedf2;
  width: 100%;
}

.add-dash-button {
  box-sizing: border-box;
  border: 1px dashed #bfc8d2;
  background-color: #fff;
  padding: 0 10px 0 10px;
  border-radius: 4px;
  max-width: 100%;
  min-width: 66px;
  height: 30px;
  color: #8b95a1;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  outline: none;

  &:hover {
    background-color: #fff !important;
  }
}

.save-cancel-body {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.user-avatar {
  width: 84px;
  height: 84px;
  position: relative;
}

.user-avatar-img {
  box-sizing: border-box;
  border: 2px solid #eaeaea;
  border-radius: 100%;
  width: 90px;
  height: 90px;
}

.add-image {
  cursor: pointer;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  background-color: #FD4056;
  color: #fff;
  position: absolute;
  right: -1px;
  top: -6px;
  display: flex;
  justify-content: center;
  align-items: center;

  .avatar-add-img /deep/ {
    width: 12px;
    height: 12px;

    path {
      fill: #fff;
    }
  }
}

.user-info {
  width: 100%;
}

.username-text {
  font-size: 26px;
  font-weight: 600;
  color: #4b4f52;
  margin-bottom: 5px;
}

.user-fistname-lastname-text {
  font-size: 18px;
  font-weight: 400;
  color: #5e6b79;
  margin-bottom: 14px;
  text-transform: capitalize;
}

.profession-text {
  font-size: 18px;
  font-weight: 600;
  color: #26ca9a;
  margin-top: 14px;
  margin-bottom: 10px;
}

.profession-description-area {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profession-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  color: #8b95a1;
  word-break: break-word;
}

/*contact info*/

.contact-info {
  display: flex;
  flex-direction: column;
}

.contact-item {
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  padding-right: 15px;

  .left {
    width: 25%;
    align-self: center;
  }

  .right {
    width: 75%;
  }
}

.contact-label {
  font-size: 16px;
  color: #8b95a1;
  font-weight: 400;
  margin: 0;
}

.contact-text {
  font-size: 16px;
  color: #2d3640;
  font-weight: 400;
  min-width: 173px;
}

.gsm {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.gsm-inactive {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #fd4056;
  width: 93px;
  height: 28px;
  color: #fd4056;
  font-size: 13px;
  font-weight: 400;
}

.gsm-active {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #26ca9a;
  padding: 0 10px 0 10px;
  width: 80px;
  height: 28px;
  color: #26ca9a;
  font-size: 13px;
  font-weight: 400;
}

.freelancer-gsm {
  display: flex;
  flex-direction: column;
}

.freelancer-gsm-item {
  display: flex;
  align-items: center;
  height: 30px;

  .freelancer-gsm-item-label {
    font-weight: 300;
    font-size: 14px;
    width: 160px;
    color: #5e6b79;
  }

  .freelancer-gsm-item-text {
    color: #2d3640;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;
  }

  .f-t-status-badge-active {
    width: 80px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    background-color: #26ca9a;
    color: #fff;
  }

  .f-t-status-badge-passive {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    width: 80px;
    height: 28px;
    font-size: 14px;
    font-weight: 400;
    background-color: #fd4056;
    color: #fff;
  }
}

.freelancer-gsm-text {
  font-size: 14px;
  font-weight: 300;
  line-height: 26px;
  color: #5e6b79;

  .red {
    font-weight: 400;
    font-style: italic;
    color: #fd4056;
  }

  .dark {
    font-weight: 800;
    color: #2d3640;
  }

  .green {
    font-weight: 800;
    color: #00a575;
  }
}

.change-f_t_line {
  background-color: #eaedf2;
  width: 215px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #8b95a1;

  span {
    font-weight: 800;
    color: #2d3640;
    margin-left: 3px;
  }
}

/*skills*/

.skill-body {
  display: flex;
  flex-direction: row;
  align-items: center;
}


.skill-item-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.skill-item {
  border: 1px solid #26ca9a;
  border-radius: 4px;
  padding: 7px 12px;
  color: #26ca9a;
  font-size: 13px;
  font-weight: 400;
  margin-right: 15px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.skill-item-sugessted {
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  padding: 5px 12px 5px 31px;
  line-height: 20px;
  font-size: 14px;
  color: #5e6b79;
  font-weight: 400;
  margin-right: 15px;
  margin-bottom: 5px;
  margin-top: 5px;
  border: 1px solid #e5e5e5;
}

.editable-skills-body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.editable-skill-item {
  cursor: pointer;
  position: relative;
  background-color: #eaedf2;
  border-radius: 4px;
  padding: 5px 28px 5px 10px;
  line-height: 20px;
  font-size: 14px;
  color: #2d3640;
  font-weight: 400;
  margin-right: 25px;
  margin-top: 30px;
  border: 1px solid #eaedf2;


  &:hover {
    background-color: #26ca9a;
    color: #fff;
    border: 1px solid #26ca9a;

    .delete-skill-icon /deep/ {
      #ic_close_black {
        fill: #fff;
      }
    }

  }
}

.suggestion-skill-item {
  cursor: pointer;
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  padding: 5px 28px 5px 31px;
  line-height: 20px;
  font-size: 14px;
  color: #5e6b79;
  font-weight: 400;
  margin-right: 25px;
  margin-top: 30px;
  border: 1px solid #e5e5e5;

  &:after {
    pointer-events: none;
  }

  &:before {
    pointer-events: none;
  }

  span {
    color: #8b95a1;
  }
}

.delete-skill-icon /deep/ {
  width: 10px;
  height: 10px;
  position: absolute;
  right: 10px;
  top: 3px;
  bottom: 0;
  margin: auto;

  #ic_close_black {
    fill: #bfc8d2;
  }
}
</style>
